.bg-mulit {
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 10px;
}

.switch-btn {
    width: 70px;
}

.refesh-btn {
    position: absolute;
    bottom: 60px;
    left: 5vw;
    width: 90vw;
}

.grid-content {
    width: 90vw;
}

.alignment {
    line-height: 20px;
}