.logout-btn {
    position: absolute;
    bottom: 60px;
    left: 10vw;
    width: 80vw;
}

.userinfo {
    height: 100px;
    background-color: #FFF8EE;
    display: flex;
    align-items: center;

    .username {
        font-size: 1.4rem;
        font-weight: bold;
        margin-left: 20px;
    }
}