.page {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;

    .page-body {
        flex: 1;
        display: flex;
        width: 100vw;
        background-color: #FAFBFC;
        flex-direction: column;
        height: calc(100vh - 51px);
    }

    .page-bottom {
        flex: 0;
        border-top: solid 1px var(--adm-color-border);
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 999;
        width: 100vw;
        height: 50px;
        background-color: white;
    }
}