.p-lg{
    padding: 20px;
}

.p-t-lg{
    padding-top: 20px;
}

.p-b-lg{
    padding-bottom: 20px;
}

.p-l-lg{
    padding-left: 20px;
}

.p-r-lg{
    padding-right: 20px;
}

.p-tb-lg{
    padding-top: 20px;
    padding-bottom: 20px;
}

.p-lr-lg{
    padding-left: 20px;
    padding-right: 20px;
}

.p-sm{
    padding: 10px;
}

.p-t-sm{
    padding-top: 10px;
}

.p-b-sm{
    padding-bottom: 10px;
}

.p-l-sm{
    padding-left: 10px;
}

.p-r-sm{
    padding-right: 10px;
}

.p-tb-sm{
    padding-top: 10px;
    padding-bottom: 10px;
}

.p-lr-sm{
    padding-left: 10px;
    padding-right: 10px;
}