/* 父		默认 水平方向,不换行 */
.flex {
	display: flex;
}

/* 父		垂直排列 */
.vertical {
	flex-direction: column;
}

/* 父		换行 */
.wrap {
	flex-wrap: wrap;
}

/* 父		反转 换行		 */
.wrap-reverse {
	flex-wrap: wrap-reverse;
}

/* 父		水平方向,左对齐 默认 */
.start {
	justify-content: flex-start;
}

/* 父		水平方向,右对齐	 */
.end {
	justify-content: flex-end;
}

/* 父		水平方向,中线对齐		 */
.center {
	justify-content: center;
}

/* 父		水平方向,平均对齐,两端不留白	 */
.between {
	justify-content: space-between;
}

/* 父		水平方向,平均对齐,两端留白	 */
.around {
	justify-content: space-around;
}

/* 父		垂直方向,顶部对齐 */
.top {
	align-items: flex-start;
}

/* 父		垂直方向,底端对齐			 */
.bottom {
	align-items: flex-end;
}

/* 父		垂直方向,中间对齐		 */
.middle {
	align-items: center;
}

/* 父		垂直方向,第一行文字的基线对齐		 */
.baseline {
	align-items: baseline;
}

/* 父		垂直方向,如果未设置高度或设为auto，将占满整个容器的高度 */
.stretch {
	align-items: stretch;
}

.grow {
	flex-grow: 2;
}