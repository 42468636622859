.m-lg{
    margin: 20px;
}

.m-t-lg{
    margin-top: 20px;
}

.m-b-lg{
    margin-bottom: 20px;
    color: red;
}

.m-l-lg{
    margin-left: 20px;
}

.m-r-lg{
    margin-right: 20px;
}

.m-tb-lg{
    margin-top: 20px;
    margin-bottom: 20px;
}

.m-lr-lg{
    margin-left: 20px;
    margin-right: 20px;
}

.m-sm{
    margin: 10px;
}

.m-t-sm{
    margin-top: 10px;
}

.m-b-sm{
    margin-bottom: 10px;
    color: red;
}

.m-l-sm{
    margin-left: 10px;
}

.m-r-sm{
    margin-right: 10px;
}

.m-tb-sm{
    margin-top: 10px;
    margin-bottom: 10px;
}

.m-lr-sm{
    margin-left: 10px;
    margin-right: 10px;
}